import React from "react";
import { graphql, Link } from "gatsby";
import BaseLayout from "../components/BaseLayout";
import { getMetaTags } from "../Utils";

const BlogIndex = ({ data }) => {
  const { edges: posts } = data.allMdx;
  const site = data.site;

  const items = posts.map(({ node: post }) => (
    <li key={post.id} className="my-4">
      <time dateTime={post.frontmatter.machineReadablePublicationDate}>
        {post.frontmatter.displayedPublicationDate}
      </time>
      <Link to={post.fields.slug}>
        <h2>{post.frontmatter.title}</h2>
      </Link>
      <p>{post.frontmatter.summary}</p>
    </li>
  ));
  const metaTags = getMetaTags({
    title: `Home Page | ${site.siteMetadata.title}`,
    siteUrl: site.siteMetadata.siteUrl,
    slug: "/",
    summary: "Home Page",
  });
  return (
    <BaseLayout>
      {metaTags}
      <ul className="list-unstyled">{items}</ul>
    </BaseLayout>
  );
};

export default BlogIndex;
// Note: the value of the `origin` field must be the same as the value of the
// value of the `dynamicPostSourceType` exported variable from `buildConstants`:
export const blogIndexQuery = graphql`
  query BlogIndex {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allMdx(
      filter: { fields: { origin: { eq: "dynamicPost" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            summary
            displayedPublicationDate: date(formatString: "MMMM DD, YYYY")
            machineReadablePublicationDate: date(formatString: "YYYY-MM-DD")
          }
          fields {
            slug
            origin
          }
        }
      }
    }
  }
`;
